


















































































































































































import RelationContactApi from '@/services/RelationContactApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import Funcs from '@/functions/Funcs'
import { isHouseNumber, isTexte } from '@/functions/formRules'
import { Ref } from 'vue-property-decorator'
import BcCountrySelect from '@/components/_base/BcCountrySelect'
import { ProfileInterface } from '@/typings/ProfileInterface'
import { isRequired } from '@bluecorner/shared'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import DisabledEmailField from '@/components/common/DisabledEmailField.vue'

@Component({
  components: {
    DisabledEmailField,
    BcCountrySelect
  }
})
export default class MyProfile extends Vue {
  @Ref() readonly form!: HTMLFormElement
  userProfile: ProfileInterface = {
    FirstName: '',
    LastName: '',
    Email: '',
    Street: '',
    HouseNumber: '',
    Telephone: '',
    PostalCode: '',
    Language: '',
    City: '',
    Country: ''
  }

  loadingSave = false
  isFormValid = true
  // form error
  error = false
  errorMessages = ''

  errorProvider = false
  errorProviderMessages = ''
  required = [isRequired()]

  rulesText = [isRequired(), isTexte('Invalid')]

  rulesHouseNumber = [isHouseNumber('Invalid house number')]

  items = [
    {
      text: 'Home',
      disabled: false,
      href: 'breadcrumbs_dashboard'
    },
    {
      text: 'Dashboard',
      disabled: true,
      href: 'breadcrumbs_link_1'
    }
  ]

  mounted() {
    this.fetchProfile()
  }

  async saveProfile() {
    if (this.form.validate()) {
      this.loadingSave = true
      const profile = Funcs.objToLowerCase(this.userProfile)
      const res = (await new RelationContactApi().createUpdate(
        profile
      )) as DefaultResponseInterface<ProfileInterface>
      this.loadingSave = false
      if (res.result.status === 'success') {
        this.$store.dispatch('user/fetchUserInfo')
        this.$store.commit('notification/showMessage', {
          content: 'Profile information saved succesfully',
          color: 'success',
          timeout: 10000
        })
      } else {
        this.$store.commit('notification/showMessage', {
          content:
            'Error occured while updating your profile, please contact our services team',
          color: 'error',
          timeout: 10000
        })
      }
    }
  }

  async fetchProfile() {
    const res =
      (await new RelationContactApi().myProfile()) as DefaultResponseInterface<ProfileInterface>
    if (res.result.status === 'success') {
      this.userProfile = res.data
    }
  }

  resetErrors() {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }
}
