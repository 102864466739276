import BaseApi from './BaseApi'
import { AxiosRequestConfig } from 'axios'
import { AddressInterface } from '@/typings/Address'

export interface RelationContactInterface {
  address?: AddressInterface
  Id?: number | string
  carId?: number | string
  category?: string
  city?: string
  City?: string
  contacttype?: string
  costcenter?: string
  country?: string
  Country?: string
  cususerid?: number | string
  department?: string
  email?: string
  emailverified?: boolean | number | string
  firstname?: string
  function?: string
  hasportal?: boolean
  housenumber?: string
  HouseNumber?: string
  id?: number | string
  isperson?: boolean | number | string
  language?: string
  lastname?: string
  line1?: string
  line2?: string
  line3?: string
  line4?: string
  line5?: string
  name?: string
  postalcode?: string
  PostalCode?: string
  relationdescriptionlist?: string
  relationid?: number | string
  relationidlist?: string
  remarkinternal?: string
  remarkpublic?: string
  searchstring?: string
  street?: string
  Street?: string
  telephone?: string
  uci?: string
  userid?: string
}

class RelationContactApi extends BaseApi {
  constructor(type = 0) {
    super('RelationContact', type)
  }

  async findContactsByRelationContactId(id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relationcontact/BCO/${id}`
    }
    return await this.getApi(options)
  }

  async createUpdate(relationContact: RelationContactInterface) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relationcontact/BCO/createupdate`,
      data: relationContact
    }
    return await this.postApi(opts)
  }

  async myProfile() {
    return await this.getApi(
      `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relationcontact/BCO/myprofile`
    )
  }

  // Todo remove code related to site survey
  async userProfile() {
    return await this.getApi(
      `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}//user/BCO/UserInfo`
    )
  }

  async createPortal(payload: RelationContactInterface) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/CreateUser`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async myRelationContacts(id: string, directonly = false) {
    let filtered = ''
    if (id.length > 0) {
      filtered = `?directonly=${directonly}&relid=${id}`
    }
    const options: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relationcontact/BCO/list${filtered}`
    }
    return await this.getApi(options)
  }

  async myRelationContactsEntityManager(id = '', directonly = false) {
    let filtered = ''
    if (id.length > 0) {
      filtered = `?directonly=${directonly}&relid=${id}&isentitymanager=0`
    }
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relationcontact/BCO/list${filtered}`
    }
    return await this.getApi(options)
  }

  async assignFleet(relationContactId: string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/assign/manager/${relationContactId}`,
      method: 'put'
    }

    return await this.putApi(options)
  }

  async removeFleet(relationContactId: string) {
    const options: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/remove/manager/${relationContactId}`,
      method: 'put'
    }

    return await this.putApi(options)
  }

  async activateUser(relationContactId: string) {
    const options: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/ActivateUser/${relationContactId}`,
      method: 'put'
    }

    return await this.putApi(options)
  }

  async deactivateUser(relationContactId: string) {
    const options: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/DeactivateUser/${relationContactId}`,
      method: 'put'
    }
    return await this.putApi(options)
  }
}

export { RelationContactApi }
export default RelationContactApi
