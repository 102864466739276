
export default class Funcs {
  static objToLowerCase (obj: any): any {
    const keys = Object.keys(obj)
    const rtn: any = {}
    keys.forEach((k: string) => {
      rtn[k.toLowerCase()] = obj[k]
    })
    return rtn
  }
}
