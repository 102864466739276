





















import { Prop, PropSync, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
@Component
export default class DisabledEmailField extends Vue {
  @PropSync('email', { type: String }) emailSync!: string

  @Prop({ type: Boolean, default: true }) readonly isDisabled!: boolean
}
